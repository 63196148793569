
    import axios from 'axios';
    import { Component, Vue } from "vue-property-decorator";
    import { GraphUtils } from '../../ts/utils/GraphUtils';

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class EconomyPreciousMetals extends Vue {
        // Methods -----------------------------------------------------------------------------------------------------
        private data() {
            return {
                goldBarsImagePathAndName:
                    require("@/assets/images/gold_bars.png"),

                goldGraphImagePathAndName:
                    require("@/assets/images/gold_graph.png"),

                silverBarsImagePathAndName:
                    require("@/assets/images/silver_bars.png"),

                silverGraphImagePathAndName:
                    require("@/assets/images/silver_graph.png"),

                preciousMetalsIconPathAndName:
                    require("@/assets/images/icon_coins.png"),
            };
        }
    }
